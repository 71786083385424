import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

//Fermer complètement la vidéo;
  $(".close-button").click(function() {
  $(".video-stick").remove();
  $("main").prepend('<div class="video-stick grid-x align-center text-right" data-sticky-container data-closable data-reset-on-close="true" data-animation-in="fade-in" data-animation-out="fade-out" data-reset-on-close="true" data-animation-out="fade-out"><div class="sticky large-6 is-anchored is-at-top" data-sticky data-top-anchor="start_vid:top" data-stick-to="bottom" data-reset-on-close="true"><iframe src="https://www.youtube.com/embed/56QSxcJ2hBQ?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><button class="close-button close-vid" aria-label="Close alert" type="button" data-close><span aria-hidden="true">&times;</span></button></div></div>');
});